import React, { Dispatch, SetStateAction, useState } from 'react';
import GoogleAutocompleteSearchBar from '../GoogleAutocompleteSearchBar';
import Button from '../Button';
import SimpleDropDown from '../SimpleDropDown';
import {
  BATHS_SELECT_OPTION,
  BEDS_SELECT_OPTION,
} from '../../utils/PropertyListingUtils';
import GroupedRadioButtons from '../GroupedRadioButtons';
import { FilterType, PropertiesFilterType } from '../../types/real-api-types';
import GroupCheckboxButtons from '../GroupCheckboxButtons';
import GroupedCheckBoxInputs from '../GroupedCheckBoxInputs';
import RangeSliderInput from '../RangeSliderInput';
import {
  DEFAULT_MAX_PRICE_RANGE,
  DEFAULT_MIN_PRICE_RANGE,
  DEFAULT_RANGE_STEPS,
  getAmenitiesOptions,
  getInitialFilter,
  getPropertyTypeOptions,
} from '../../utils/PropertiesUtils';
import ListingGroupedRadioButtons from './ListingGroupedRadioButtons';
import ListingButton, { ListingButtonAccentColor } from './ListingButton';
import ListingFiltersSm from './ListingFiltersSm';

export const filterTypeLabelMap: Record<FilterType, string> = {
  buy: 'For Sale',
  rent: 'For Rent',
  mls: 'For Mls',
};

export const filterTypeAccentColorMap: Record<
  FilterType,
  ListingButtonAccentColor
> = {
  buy: 'primary',
  rent: 'warning',
  mls: 'warning',
};

interface ListingFiltersProps {
  filter: PropertiesFilterType;
  updateFilter(key: keyof PropertiesFilterType, value: any): void;
  setFilter: Dispatch<SetStateAction<PropertiesFilterType>>;
}

const ListingFilters: React.FC<ListingFiltersProps> = ({
  filter,
  updateFilter,
  setFilter,
}) => {
  const [isFilterOpen, setIsFilterOpen] = useState<boolean>(false);

  return (
    <div className='mt-3 md:mb-3 px-4'>
      <div className='flex items-center space-x-2.5'>
        <div className='w-full md:max-w-xs border rounded-md'>
          <GoogleAutocompleteSearchBar
            initialPlace={filter.search_label}
            onPlaceChange={(place) => {
              setFilter(getInitialFilter(place, filter));
            }}
            placeholder='Enter location, address, or ZIP'
            variant='secondary'
          />
        </div>
        <div className='md:hidden'>
          <Button
            title='Filters'
            size='lg'
            onClick={() => setIsFilterOpen(true)}
            buttonStyles='!px-0'
            textStyles='!px-2 text-base'
          />
          {isFilterOpen && (
            <ListingFiltersSm
              isOpen={isFilterOpen}
              closeMenu={() => setIsFilterOpen(false)}
              filter={filter}
              setFilter={setFilter}
            />
          )}
        </div>
        <div className='hidden md:flex items-center space-x-2.5'>
          <SimpleDropDown
            buttonComponent={
              <ListingButton
                label={filterTypeLabelMap[filter.type]}
                accentColor={filterTypeAccentColorMap[filter.type]}
              />
            }
          >
            {() => (
              <ListingGroupedRadioButtons
                options={[
                  { label: 'For Sale', value: 'buy', accentColor: 'primary' },
                  {
                    label: 'For Rent',
                    value: 'rent',
                    accentColor: 'warning',
                  },
                  // { label: 'MLS', value: 'mls' },
                ]}
                value={filter.type}
                onChange={(value) => updateFilter('type', value)}
              />
            )}
          </SimpleDropDown>
          <SimpleDropDown buttonComponent={<ListingButton label='Price' />}>
            {() => (
              <RangeSliderInput
                label='Price'
                onChange={(minPrice, maxPrice) => {
                  if (minPrice !== filter.price_min) {
                    updateFilter(
                      'price_min',
                      !!minPrice && minPrice !== '0' ? minPrice : undefined
                    );
                  }

                  if (maxPrice !== filter.price_max) {
                    updateFilter(
                      'price_max',
                      !!maxPrice && maxPrice !== '0' ? maxPrice : undefined
                    );
                  }
                }}
                minValue={filter.price_min || '0'}
                maxValue={filter.price_max || '0'}
                minValuePlaceholder='Min Price'
                maxValuePlaceholder='Max Price'
                min={DEFAULT_MIN_PRICE_RANGE}
                max={DEFAULT_MAX_PRICE_RANGE}
                step={DEFAULT_RANGE_STEPS}
              />
            )}
          </SimpleDropDown>
          <SimpleDropDown buttonComponent={<ListingButton label='Home Type' />}>
            {() => (
              <div className='w-full max-w-xs'>
                <GroupCheckboxButtons
                  label='Property Type'
                  options={getPropertyTypeOptions(filter, updateFilter)}
                />
              </div>
            )}
          </SimpleDropDown>
          <SimpleDropDown
            buttonComponent={<ListingButton label='Beds & Baths' />}
          >
            {() => (
              <div className='space-y-7'>
                <GroupedRadioButtons
                  label='Beds'
                  value={filter.beds_min}
                  onChange={(value) => {
                    if (filter.beds_min !== value) {
                      updateFilter('beds_min', value);
                    } else {
                      updateFilter('beds_min', undefined);
                    }
                  }}
                  options={BEDS_SELECT_OPTION}
                />
                <GroupedRadioButtons
                  label='Baths'
                  value={filter.baths_min}
                  onChange={(value) => {
                    if (filter.baths_min !== value) {
                      updateFilter('baths_min', value);
                    } else {
                      updateFilter('baths_min', undefined);
                    }
                  }}
                  options={BATHS_SELECT_OPTION}
                />
              </div>
            )}
          </SimpleDropDown>
          <SimpleDropDown buttonComponent={<ListingButton label='Amenities' />}>
            {() => (
              <GroupedCheckBoxInputs
                label='Amenities'
                options={getAmenitiesOptions(filter, updateFilter)}
              />
            )}
          </SimpleDropDown>
        </div>
      </div>
    </div>
  );
};

export default ListingFilters;
