import React from 'react';
import ListingButton, { ListingButtonAccentColor } from './ListingButton';

interface ListingGroupedRadioButton {
  label: string;
  value: string;
  accentColor: ListingButtonAccentColor;
}

interface ListingGroupedRadioButtonsProps {
  label?: string;
  options: ListingGroupedRadioButton[];
  value: string;
  onChange(value: string): void;
}

const ListingGroupedRadioButtons: React.FC<ListingGroupedRadioButtonsProps> = ({
  label,
  options,
  onChange,
  value,
}) => {
  const valueSelected = options.find((option) => option.value === value)?.value;

  return (
    <div>
      {label && <p className='font-primary-medium text-dark mb-3'>{label}</p>}
      <div className='flex flex-col space-y-5'>
        {options.map((option) => (
          <ListingButton
            key={option.label}
            label={option.label}
            onClick={() => onChange(option.value)}
            active={valueSelected === option.value}
            accentColor={option.accentColor}
            hideArrow
          />
        ))}
      </div>
    </div>
  );
};

export default ListingGroupedRadioButtons;
