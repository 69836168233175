// eslint-disable-next-line import/no-nodejs-modules
import { ParsedUrlQueryInput } from 'querystring';
import { debounce } from '@mui/material';
import { isEqual, isNaN, isNil } from 'lodash';
import { GetServerSideProps, NextPage } from 'next';
import { useRouter } from 'next/router';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import AgentLayoutRedesigned from '../../components/agent/AgentLayoutRedesigned';
import Container from '../../components/Container';
import LayoutRedesigned from '../../components/LayoutRedesigned';
import ListingFilters from '../../components/listings/ListingFilters';
import ListingHeader from '../../components/listings/ListingHeader';
import ListingPagination from '../../components/listings/ListingPagination';
import PropertyCard, { HomeTypeEnum } from '../../components/PropertyCard';
import PropertyLocationsMap from '../../components/PropertyLocationsMap';
import ThemeProvider from '../../components/ThemeProvider';
import {
  OfficesResponse,
  PropertyFilterResponseHomeJunctionPropertyTypesEnum,
} from '../../openapi/bff';
import ContentfulService from '../../services/ContentfulService';
import { CommonPageDataProps } from '../../types';
import { TypeFooter } from '../../types/generated/page-contents';
import {
  FilterType,
  PropertiesFilterType,
  PropertiesResponse,
  RealApiAgentWebsiteResponse,
} from '../../types/real-api-types';
import { getAgentWebsiteApiResponse } from '../../utils/agentWebsiteUtils';
import { MoneyValueCurrencyEnum } from '../../utils/CurrencyUtils';
import ServerLogger from '../../utils/ServerLogger';
import {
  getFilteredProperties,
  getFilterFromQuery,
  getOnlyAppliedFilters,
  getPropertyDetailPath,
  getTotalPropertiesPages,
  isPropertiesFilterAPICallable,
} from '../../utils/PropertiesUtils';
import BFFApiService from '../../services/BFFApiService';

export interface ListingFormData {
  priceRange: number;
  bedrooms: string;
  bathrooms: string;
  propertyType: PropertyFilterResponseHomeJunctionPropertyTypesEnum;
  amenities: any;
}

interface ListingsProps extends CommonPageDataProps {
  propertiesRes: PropertiesResponse;
  agent: RealApiAgentWebsiteResponse;
  footerContent: TypeFooter;
  allOffices: OfficesResponse;
}

const Listings: NextPage<ListingsProps> = ({
  countriesWithStates,
  footerContent,
  propertiesRes,
  agent,
  allOffices,
}) => {
  const router = useRouter();
  const filterQS = getFilterFromQuery(router.query);
  const propertyListRef = useRef<HTMLDivElement>(null);
  const [filter, setFilter] = useState<PropertiesFilterType>(filterQS);
  const [showMap, setShowMap] = useState<boolean>(false);
  const currentPath = router?.asPath as string;
  const defaultPageQuery: ParsedUrlQueryInput = {};

  const updateFilter = (key: keyof PropertiesFilterType, value: any): void => {
    let newFilterToApply = filter;

    if (value !== undefined && value !== null && value !== '') {
      newFilterToApply = { ...newFilterToApply, [key]: value };
    } else {
      delete newFilterToApply[key];
    }

    if (newFilterToApply.type === 'rent') {
      newFilterToApply.is_land = false;
      newFilterToApply.is_multifamily = false;
    }
    if (key !== 'page_number') {
      newFilterToApply.page_number = 0;
    }
    setFilter(getOnlyAppliedFilters(newFilterToApply));
  };

  const handleFilterStateChange = (
    modifiedFilter: PropertiesFilterType,
    filterFromQS: PropertiesFilterType
  ): void => {
    if (!isEqual(modifiedFilter, filterFromQS)) {
      router.push({
        query: {
          ...modifiedFilter,
          ...defaultPageQuery,
          agentSlug: agent.agent.slug,
        },
      });
    }
  };

  useEffect(() => {
    if (isNil(filterQS?.page_number) || isNaN(filterQS?.page_number)) {
      updateFilter('page_number', 0);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterQS?.page_number]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleFilterStateChangeDebounce = useCallback(
    debounce(handleFilterStateChange, 500),
    []
  );

  useEffect(() => {
    if (propertiesRes) {
      propertyListRef.current?.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }, [propertiesRes]);

  useEffect(() => {
    if (filter.google_place_id) {
      handleFilterStateChangeDebounce(filter, filterQS);
    }
  }, [filter, filterQS, handleFilterStateChangeDebounce]);

  if (!propertiesRes?.properties?.length) {
    return (
      <ThemeProvider theme={agent.website.color_theme}>
        <LayoutRedesigned
          countriesWithStates={countriesWithStates}
          footerContent={footerContent}
          allOffices={allOffices}
        >
          <ListingFilters
            filter={filter}
            updateFilter={updateFilter}
            setFilter={setFilter}
          />
          <div className='flex w-full h-52 items-center justify-center border-t'>
            Start searching for properties above
          </div>
        </LayoutRedesigned>
      </ThemeProvider>
    );
  }

  return (
    <ThemeProvider theme={agent.website.color_theme}>
      <AgentLayoutRedesigned
        countriesWithStates={countriesWithStates}
        agentWebsiteResponse={agent}
        legalese={propertiesRes.legalese}
        footerContent={footerContent}
        allOffices={allOffices}
      >
        <ListingFilters
          filter={filter}
          updateFilter={updateFilter}
          setFilter={setFilter}
        />
        <div className='md:border-t'>
          <div className='md-mx-0 md:flex'>
            <div className='hidden md:block flex-1 border h-[calc(100vh-48px)] sticky top-[48px]'>
              <PropertyLocationsMap
                markers={propertiesRes.properties.map((value) => ({
                  lat: value.gps_2[0],
                  lng: value.gps_2[1],
                  price: value.price_short,
                  href: getPropertyDetailPath(value, undefined, currentPath),
                }))}
              />
            </div>
            <div className='flex-1'>
              <ListingHeader
                totalProperties={propertiesRes.total_properties}
                filter={filter}
                updateFilter={updateFilter}
                showMap={showMap}
                onChangeShowMap={setShowMap}
              />
              <div className='md:hidden'>
                {showMap ? (
                  <div className='-mx-4 h-[80vh]'>
                    <PropertyLocationsMap
                      markers={propertiesRes.properties.map((value) => ({
                        lat: value.gps_2[0],
                        lng: value.gps_2[1],
                        price: value.price_short,
                        href: getPropertyDetailPath(
                          value,
                          undefined,
                          currentPath
                        ),
                      }))}
                    />
                  </div>
                ) : (
                  <Container>
                    <div className='grid grid-cols-1 place-items-center space-y-5'>
                      {propertiesRes.properties.map((property) => (
                        <PropertyCard
                          homeType={
                            property.is_rental
                              ? HomeTypeEnum.Rent
                              : HomeTypeEnum.Sale
                          }
                          key={`${property.mls_id}-${property.mls}-${property.mlsnum}`}
                          addressLine1={property.address1}
                          addressLine2={property.address2}
                          numBeds={property.beds}
                          numBaths={property.baths}
                          propertyType={property.property_type}
                          sq_ft={property.sq_ft}
                          price={{
                            amount: property.price_short,
                            currency: MoneyValueCurrencyEnum.Usd,
                          }}
                          imgUrl={
                            property.photos.length > 0
                              ? property.photos[0].thumbnail
                              : '/img/real-article-thumb.png'
                          }
                          href={getPropertyDetailPath(
                            property,
                            agent.agent.slug,
                            currentPath
                          )}
                          isRowCardOnMobile
                          legalese={property.legalese}
                          attributionContact={property?.attributionContact}
                        />
                      ))}
                    </div>
                    <ListingPagination
                      currentPage={propertiesRes.page}
                      totalPagesCount={getTotalPropertiesPages(
                        propertiesRes.total_properties
                      )}
                      onPageChange={(page) => updateFilter('page_number', page)}
                      hasNext={propertiesRes.has_more_pages}
                    />
                  </Container>
                )}
              </div>
              {/* MOBILE - END*/}

              <div ref={propertyListRef} className='hidden md:block'>
                <Container>
                  <div className='grid grid-cols-1 lg:grid-cols-2 gap-5 place-items-center'>
                    {propertiesRes.properties.map((property) => (
                      <PropertyCard
                        key={`${property.mls_id}-${property.mls}-${property.mlsnum}`}
                        addressLine1={property.address1}
                        addressLine2={property.address2}
                        numBeds={property.beds}
                        numBaths={property.baths}
                        homeType={
                          property.is_rental
                            ? HomeTypeEnum.Rent
                            : HomeTypeEnum.Sale
                        }
                        propertyType={property.property_type}
                        sq_ft={property.sq_ft}
                        price={{
                          amount: property.price_short,
                          currency: MoneyValueCurrencyEnum.Usd,
                        }}
                        imgUrl={
                          property.photos.length > 0
                            ? property.photos[0].thumbnail
                            : '/img/real-article-thumb.png'
                        }
                        href={getPropertyDetailPath(
                          property,
                          agent.agent.slug,
                          currentPath
                        )}
                        legalese={property.legalese}
                        attributionContact={property?.attributionContact}
                      />
                    ))}
                  </div>
                  <ListingPagination
                    currentPage={propertiesRes.page}
                    totalPagesCount={getTotalPropertiesPages(
                      propertiesRes.total_properties
                    )}
                    onPageChange={(page) => updateFilter('page_number', page)}
                    hasNext={propertiesRes.has_more_pages}
                  />
                </Container>
              </div>
            </div>
          </div>
        </div>
      </AgentLayoutRedesigned>
    </ThemeProvider>
  );
};

export const getServerSideProps: GetServerSideProps<ListingsProps> = async (
  ctx
) => {
  const contentfulService = new ContentfulService();
  const bffApiService = new BFFApiService();

  const [countriesWithStates, footerContent, allOffices] = await Promise.all([
    contentfulService.getCountriesWithStates(),
    contentfulService.getFooterContent(),
    bffApiService.fetchAllOfficialOffices(),
  ]);

  let propertiesRes: PropertiesResponse;
  if (isPropertiesFilterAPICallable(ctx.query)) {
    try {
      propertiesRes = (await getFilteredProperties(
        getFilterFromQuery(ctx.query)
      )) as PropertiesResponse;
    } catch (e) {}
  }

  const agentWebsiteResponse: RealApiAgentWebsiteResponse =
    await getAgentWebsiteApiResponse(ctx.params.agentSlug as string);

  const defaultPropertyResponse = {
    disclaimers: [],
    legalese: [],
    properties: [],
    filter: { type: (ctx?.query?.type as unknown as FilterType) ?? 'buy' },
    has_more_pages: true,
    latitude: 0,
    page: 0,
    total_properties: 0,
  };

  if (!agentWebsiteResponse) {
    ServerLogger.warn(
      `Could not find agent website for slug=${ctx.params.agentSlug}. Redirecting to listings.`
    );

    return {
      redirect: {
        destination: `listings/${ctx.params}`,
      },
      props: {
        propertiesRes: propertiesRes || defaultPropertyResponse,
        agent: agentWebsiteResponse,
        countriesWithStates,
        footerContent,
        allOffices,
      },
    };
  }

  return {
    props: {
      countriesWithStates,
      footerContent,
      agent: agentWebsiteResponse,
      propertiesRes:
        (propertiesRes as PropertiesResponse) || defaultPropertyResponse,
      allOffices,
    },
  };
};

export default Listings;
