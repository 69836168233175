import React from 'react';
import classNames from 'classnames';
import { LegaleseElement } from '../openapi/wanderer';
import MLSDisplayText from './MLSDisplayText';

interface PropertyCourtesyProps {
  legalese: Array<LegaleseElement>;
  containerStyle?: string;
  attributionContact?: string;
}

const PropertyCourtesy: React.FC<PropertyCourtesyProps> = ({
  legalese,
  containerStyle,
  attributionContact,
}) => {
  const courtesy = (legalese || []).find(
    (v) => v?.disclaimerType === 'courtesy'
  );
  const nwmlsLegalese = (legalese || []).find(
    (v) => v?.mlsId === 'nwmls' && !!v?.asset
  );

  const njmlsLease = (legalese || []).find(
    (v) => v?.mlsId === 'njmls' && !!v?.asset
  );

  if (!courtesy?.text) {
    return null;
  }

  const mlsToDisplay = njmlsLease || nwmlsLegalese;
  const mlsName =
    mlsToDisplay?.mlsId === 'njmls'
      ? 'NJMLS'
      : mlsToDisplay?.mlsId === 'nwmls'
      ? 'NWMLS'
      : null;

  return (
    <div
      className={classNames(
        'pt-3.5 pb-1 px-4 md:px-0 font-primary-regular text-sm text-dark',
        containerStyle
      )}
      data-testid='property-courtesy'
    >
      <span>{courtesy?.text}</span>
      {!!attributionContact && <span>{attributionContact}</span>}
      <MLSDisplayText mls={mlsToDisplay} mlsName={mlsName} />
    </div>
  );
};

export default PropertyCourtesy;
