import React from 'react';
import classNames from 'classnames';
import Image from 'next/image';
import SimpleDropDown from '../SimpleDropDown';
import {
  PropertiesFilterSortType,
  PropertiesFilterType,
} from '../../types/real-api-types';
import ListingSortOptions from './ListingSortOptions';
import ListingButton from './ListingButton';
import ListingGroupedRadioButtons from './ListingGroupedRadioButtons';
import { filterTypeAccentColorMap, filterTypeLabelMap } from './ListingFilters';

const sortTypeLabelMap: Record<PropertiesFilterSortType, string> = {
  cheapest: 'Price (Low to High)',
  most_expensive: 'Price (High to Low)',
  newest: 'Newest',
  distance: 'Distance',
};

interface ListingHeaderProps {
  totalProperties: number;
  filter: PropertiesFilterType;
  updateFilter(key: keyof PropertiesFilterType, value: any): void;
  showMap: boolean;
  onChangeShowMap(val: boolean): void;
}

const ListingHeader: React.FC<ListingHeaderProps> = ({
  totalProperties,
  filter,
  updateFilter,
  showMap,
  onChangeShowMap,
}) => {
  return (
    <div className='flex items-center justify-between pt-4 px-4 md:pt-6 pb-5 md:pb-3'>
      <div className='md:hidden flex items-center space-x-8'>
        <SimpleDropDown
          buttonComponent={
            <ListingButton
              label={filterTypeLabelMap[filter.type]}
              accentColor={filterTypeAccentColorMap[filter.type]}
              hideArrow
            />
          }
        >
          {(closeDropDown) => (
            <ListingGroupedRadioButtons
              options={[
                { label: 'For Sale', value: 'buy', accentColor: 'primary' },
                {
                  label: 'For Rent',
                  value: 'rent',
                  accentColor: 'warning',
                },
                // { label: 'MLS', value: 'mls' },
              ]}
              value={filter.type}
              onChange={(value) => {
                updateFilter('type', value);
                closeDropDown();
              }}
            />
          )}
        </SimpleDropDown>
        <div className='flex items-center space-x-2.5'>
          <button
            className={classNames('rounded p-2', {
              'bg-opacity-5 bg-black': !showMap,
            })}
            onClick={() => onChangeShowMap(false)}
          >
            <div className='relative w-6 h-6'>
              <Image
                src='/img/listing.svg'
                alt='listing'
                layout='fill'
                objectFit='contain'
              />
            </div>
          </button>
          <div className='relative w-1 h-8'>
            <Image
              src='/img/line.svg'
              alt='line'
              layout='fill'
              objectFit='contain'
            />
          </div>
          <button
            className={classNames('rounded p-2', {
              'bg-black bg-opacity-5': showMap,
            })}
            onClick={() => onChangeShowMap(true)}
          >
            <div className='relative w-6 h-6'>
              <Image
                src='/img/map.svg'
                alt='map'
                layout='fill'
                objectFit='contain'
              />
            </div>
          </button>
        </div>
      </div>
      <div className='flex items-center-justify-between space-x-3 md:flex-grow'>
        <p className='text-gray-400 font-primary-regular flex-1'>
          {totalProperties} Listings
        </p>
        <SimpleDropDown
          buttonComponent={
            <div className='md:hidden'>
              <Image
                src='/img/sort-arrows.svg'
                alt='sort'
                width={14}
                height={16}
              />
            </div>
          }
        >
          {(closeDropDown) => (
            <ListingSortOptions
              value={filter.sort_by}
              onChange={(value) => {
                updateFilter('sort_by', value);
                closeDropDown();
              }}
            />
          )}
        </SimpleDropDown>
        <div className='hidden md:flex items-center space-x-1 text-dark font-primary-regular'>
          <div className='flex items-center'>
            <p className='text-gray-400'>Sort By: </p>
          </div>
          <SimpleDropDown
            buttonComponent={
              <div>
                <div className='flex items-center space-x-1 cursor-pointer'>
                  <p>
                    {filter.sort_by ? sortTypeLabelMap[filter.sort_by] : 'None'}
                  </p>
                  <div>
                    <Image
                      src='/img/arrow-down.svg'
                      alt='arrow-down'
                      width={10}
                      height={8}
                    />
                  </div>
                </div>
              </div>
            }
          >
            {(closeDropDown) => (
              <ListingSortOptions
                value={filter.sort_by}
                onChange={(value) => {
                  updateFilter('sort_by', value);
                  closeDropDown();
                }}
              />
            )}
          </SimpleDropDown>
        </div>
      </div>
    </div>
  );
};

export default ListingHeader;
