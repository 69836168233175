import React from 'react';
import { isEmpty } from 'lodash';
import classNames from 'classnames';
import { LegaleseElement } from '../openapi/wanderer';

export interface MLSDisplayTextProps {
  mls: LegaleseElement;
  mlsName: string;
}

const MLSDisplayText: React.FC<MLSDisplayTextProps> = ({ mls, mlsName }) => {
  if (isEmpty(mls) || mlsName === undefined) {
    return null;
  }

  const isMLSAssetWide =
    mls.asset.width > 0 && mls.asset.width > mls.asset.height * 1.5;

  return (
    <React.Fragment>
      <span className='font-primary-regular text-sm text-dark pl-1 mr-2'>
        {mlsName}
      </span>
      {!!mls?.asset?.url && (
        <div
          className={classNames(
            isMLSAssetWide
              ? 'inline-block h-6 w-16 -mb-4'
              : 'inline-block h-6 w-8 -mb-1.5'
          )}
        >
          {/*eslint-disable-next-line @next/next/no-img-element  */}
          <img src={mls?.asset?.url} alt='mls' />
        </div>
      )}
    </React.Fragment>
  );
};

export default MLSDisplayText;
