import React from 'react';
import classNames from 'classnames';

export interface GroupButtonOption {
  label: string;
  value: string;
}

export interface GroupRadioButtonsInterface {
  label?: string;
  options: GroupButtonOption[];
  value: string;
  onChange(value: string): void;
}

const GroupedRadioButtons: React.FC<GroupRadioButtonsInterface> = ({
  label,
  value,
  options,
  onChange,
}) => {
  const valueSelected = options.find((option) => option.value === value)?.value;

  return (
    <div>
      {label && <p className='font-primary-medium text-dark mb-3'>{label}</p>}
      <div className='flex flex-row flex-nowrap overflow-hidden space-x-1.5'>
        {options.map((option) => (
          <button
            key={option.label}
            className={classNames(
              'appearance-none outline-none focus:outline-none px-3 py-1 rounded-md overflow-hidden',
              valueSelected === option.value
                ? 'font-primary-medium border-black bg-black bg-opacity-5 border border-gray-200'
                : 'font-primary-light border bg-white'
            )}
            onClick={() => onChange(option.value)}
          >
            {option.label}
          </button>
        ))}
      </div>
    </div>
  );
};

export default GroupedRadioButtons;
