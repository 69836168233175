// eslint-disable-next-line import/no-nodejs-modules
import { UrlObject } from 'url';
import Image, { StaticImageData } from 'next/image';
import Link from 'next/link';
import React from 'react';
import classNames from 'classnames';
import { displayAmount, MoneyValue } from '../utils/CurrencyUtils';
import { capitalizeEnum } from '../utils/StringUtils';
import { LegaleseElement } from '../openapi/wanderer';
import Button from './Button';
import PropertyCourtesy from './PropertyCourtesy';

interface PropertyCardProps {
  imgUrl?: string | StaticImageData;
  price: MoneyValue;
  numBeds?: number;
  numBaths?: number;
  homeType?: string;
  propertyType?: string;
  sq_ft?: number;
  addressLine1?: string;
  addressLine2?: string;
  href: string | UrlObject;
  isRowCardOnMobile?: boolean;
  legalese?: LegaleseElement[];
  attributionContact?: string;
}

export const HomeTypeEnum = {
  Rent: 'RENT',
  Sale: 'SALE',
} as const;

const PropertyCard: React.FC<PropertyCardProps> = ({
  numBeds,
  numBaths,
  sq_ft,
  homeType,
  propertyType,
  addressLine1,
  addressLine2,
  imgUrl,
  href,
  price,
  isRowCardOnMobile = false,
  legalese,
  attributionContact,
}) => {
  return (
    <div className='w-full cursor-pointer drop-shadow-md transition duration-200 ease-in-out hover:drop-shadow-lg'>
      <Link href={href}>
        <div className='w-full bg-white rounded-xl'>
          <div
            className={classNames(
              'flex',
              isRowCardOnMobile
                ? 'flex-row p-2 md:p-0 md:flex-col overflow-hidden flex-shrink'
                : 'flex-col'
            )}
          >
            <div>
              <div
                className={classNames(
                  'overflow-hidden rounded-xl relative',
                  isRowCardOnMobile
                    ? 'h-24 w-32 md:h-56 md:w-auto md:rounded-b-none'
                    : 'h-56 rounded-b-none'
                )}
              >
                {!isRowCardOnMobile && (
                  <div className='inline-flex absolute top-5 left-5 px-3 py-0.5 bg-white bg-opacity-50 rounded-md z-10'>
                    <p className='font-primary-regular text-sm'>
                      {propertyType}
                    </p>
                  </div>
                )}
                <div>
                  <Image
                    src={imgUrl!}
                    alt='Property'
                    className={classNames(
                      isRowCardOnMobile ? 'rounded-md' : 'rounded-t-xl'
                    )}
                    layout='fill'
                  />
                </div>
              </div>
            </div>
            <div
              className={classNames(
                'flex flex-col',
                isRowCardOnMobile ? 'flex-grow pl-2 md:p-5' : 'w-full p-5'
              )}
            >
              <div className='flex flex-row items-start flex-shrink'>
                <div
                  className={classNames(
                    'w-4 h-4 relative',
                    isRowCardOnMobile ? 'hidden md:block' : ''
                  )}
                >
                  <Image
                    src='/img/property-location.svg'
                    width={16}
                    height={16}
                    alt='area'
                    layout='fill'
                    objectFit='fill'
                  />
                </div>
                <p
                  className={classNames(
                    'font-primary-regular text-base leading-5 w-full mx-1',
                    isRowCardOnMobile ? 'hidden md:block' : ''
                  )}
                >
                  {addressLine1}, {addressLine2}
                </p>
                {propertyType && (
                  <div
                    className={classNames(
                      'bg-slate-200 py-0.5 px-2 text-xs rounded',
                      isRowCardOnMobile ? 'block md:hidden' : 'hidden'
                    )}
                  >
                    {capitalizeEnum(propertyType)}
                  </div>
                )}
                <p
                  className={classNames(
                    'font-primary-regular text-lg leading-5 text-primary',
                    isRowCardOnMobile ? 'ml-auto' : ''
                  )}
                >
                  {displayAmount(price, {
                    hideCurrency: true,
                    hideZeroCents: true,
                  })}
                </p>
              </div>

              <div
                className={classNames(
                  'flex flex-row items-center justify-between w-full h-8 my-2',
                  isRowCardOnMobile ? 'py-3 md:py-4' : 'py-4'
                )}
              >
                {!!numBeds && (
                  <div className='flex flex-row'>
                    <div className='w-4 h-4 relative'>
                      <Image
                        src='/img/iconbed.svg'
                        alt='bed'
                        layout='fill'
                        objectFit='fill'
                      />
                    </div>
                    <p className='text-sm pl-1 font-primary-regular text-gray-800'>
                      {numBeds} Beds
                    </p>
                  </div>
                )}
                {!!numBaths && (
                  <div className='flex flex-row justify-center'>
                    <div className='w-4 h-4 relative'>
                      <Image
                        src='/img/iconbath.svg'
                        alt='bath'
                        layout='fill'
                        objectFit='fill'
                      />
                    </div>
                    <p className='text-sm pl-1 font-primary-regular text-gray-800'>
                      {numBaths} Baths
                    </p>
                  </div>
                )}
                {!!sq_ft && (
                  <div className='flex flex-row items-center'>
                    <div className='w-4 h-4 relative'>
                      <Image
                        src='/img/iconarea.svg'
                        alt='area'
                        layout='fill'
                        objectFit='fill'
                      />
                    </div>
                    <p className='text-sm pl-1 font-primary-regular text-gray-800'>
                      {sq_ft} sq.ft.
                    </p>
                  </div>
                )}
              </div>
              <div
                className={classNames(
                  'flex-row items-center justify-between',
                  isRowCardOnMobile ? 'hidden md:flex ' : 'flex'
                )}
              >
                {homeType === HomeTypeEnum.Sale ? (
                  <div className='flex flex-row items-center'>
                    <div className='w-2 h-2 bg-blue-400 rounded-full' />
                    <p className='text-sm p-2 font-primary-regular text-gray-400'>
                      For Sale
                    </p>
                  </div>
                ) : (
                  <div className='flex flex-row items-center'>
                    <div className='w-2 h-2 bg-amber-500 rounded-full' />
                    <p className='text-sm p-2 font-primary-regular text-gray-400'>
                      For Rent
                    </p>
                  </div>
                )}
                <Button
                  size='xs'
                  title='View Details'
                  variant='black'
                  type='button'
                />
              </div>
              <div
                className={classNames(
                  'flex',
                  isRowCardOnMobile ? 'md:hidden' : 'hidden'
                )}
              >
                <div className='flex items-start relative w-3 h-3'>
                  <Image
                    src='/img/iconlocation.svg'
                    width={14}
                    height={14}
                    alt='area'
                    className='hidden'
                    layout='fill'
                    objectFit='fill'
                  />
                </div>
                <div className='pl-0.5 text-xs text-gray-400'>
                  {addressLine1}, {addressLine2}
                </div>
              </div>
              {!!legalese?.length && (
                <PropertyCourtesy
                  legalese={legalese}
                  attributionContact={attributionContact}
                  containerStyle='px-0'
                />
              )}
            </div>
          </div>
        </div>
      </Link>
    </div>
  );
};

export default PropertyCard;
