import React from 'react';
import classNames from 'classnames';
import { CheckboxInputProps } from './CheckboxInput';

interface GroupCheckboxButtonsProps {
  options: CheckboxInputProps[];
  label: string;
}

const GroupCheckboxButtons: React.FC<GroupCheckboxButtonsProps> = ({
  label,
  options,
}) => {
  return (
    <div>
      <p className='font-primary-medium text-dark mb-3'>{label}</p>
      <div className='flex flex-row flex-wrap'>
        {options.map((option) => (
          <div key={option.label} className='pr-3 pb-2'>
            <button
              key={option.label}
              className={classNames(
                'appearance-none outline-none focus:outline-none px-3 py-1 rounded-md overflow-hidden',
                option.value
                  ? 'font-primary-medium border-black bg-black bg-opacity-5 border border-gray-200'
                  : 'font-primary-light border bg-white'
              )}
              onClick={() => option.onChange(!option.value)}
            >
              {option.label}
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default GroupCheckboxButtons;
