import React from 'react';

const options = [
  { label: 'Price (Low to High)', value: 'cheapest' },
  { label: 'Price (High to Low)', value: 'most_expensive' },
  { label: 'Newest', value: 'newest' },
  { label: 'Distance', value: 'distance' },
];

interface ListingSortOptionProps {
  label?: string;
  value: string;
  onChange(value: string): void;
}

const ListingSortOptions: React.FC<ListingSortOptionProps> = ({
  label,
  onChange,
}) => {
  return (
    <div>
      {label && <p className='font-primary-medium text-dark mb-3'>{label}</p>}
      <div className='flex flex-col space-y-2'>
        {options.map((option) => (
          <p
            key={option.label}
            className='font-primary-medium text-gray-600 hover:text-black cursor-pointer text-base'
            onClick={() => onChange(option.value)}
          >
            {option.label}
          </p>
        ))}
      </div>
    </div>
  );
};

export default ListingSortOptions;
