import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import Image from 'next/image';
import classNames from 'classnames';
import Container from '../Container';
import Button from '../Button';
import {
  BATHS_SELECT_OPTION,
  BEDS_SELECT_OPTION,
} from '../../utils/PropertyListingUtils';
import { PropertiesFilterType } from '../../types/real-api-types';
import GroupCheckboxButtons from '../GroupCheckboxButtons';
import RangeSliderInput from '../RangeSliderInput';
import {
  DEFAULT_MAX_PRICE_RANGE,
  DEFAULT_MIN_PRICE_RANGE,
  DEFAULT_RANGE_STEPS,
  FILTER_KEYS_TO_CLEAR,
  getAmenitiesOptions,
  getOnlyAppliedFilters,
  getPropertyTypeOptions,
  resetFilter,
} from '../../utils/PropertiesUtils';
import GroupedRadioButtons from '../GroupedRadioButtons';
import GroupedCheckBoxInputs from '../GroupedCheckBoxInputs';

interface ListingFiltersSmProps {
  isOpen: boolean;
  filter: PropertiesFilterType;
  setFilter: Dispatch<SetStateAction<PropertiesFilterType>>;
  closeMenu(): void;
}

const ListingFiltersSm: React.FC<ListingFiltersSmProps> = ({
  isOpen,
  closeMenu,
  filter,
  setFilter,
}) => {
  const [currentFilter, setCurrentFilter] =
    useState<PropertiesFilterType>(filter);

  useEffect(() => {
    document.body.classList.add('overflow-hidden');
    return () => document.body.classList.remove('overflow-hidden');
  }, []);

  if (!isOpen) {
    return null;
  }

  const updateFilter = (key: keyof PropertiesFilterType, value: any): void => {
    let newFilterToApply = filter;

    if (value !== undefined && value !== null && value !== '') {
      newFilterToApply = { ...newFilterToApply, [key]: value };
    } else {
      delete newFilterToApply[key];
    }

    if (newFilterToApply.type === 'rent') {
      newFilterToApply.is_land = false;
      newFilterToApply.is_multifamily = false;
    }
    if (key !== 'page_number') {
      newFilterToApply.page_number = 0;
    }
    setCurrentFilter(getOnlyAppliedFilters(newFilterToApply));
  };

  const clearFilters = (): void => {
    setCurrentFilter(resetFilter(filter, FILTER_KEYS_TO_CLEAR));
  };

  const handleSubmit = (): void => {
    setFilter(currentFilter);
    closeMenu();
  };

  return (
    <div
      className={classNames(
        'bg-white fixed inset-0 h-screen w-screen pt-5 z-50'
      )}
    >
      <div className='relative h-full pb-24 overflow-y-scroll'>
        <Container>
          <div className='flex items-center justify-between'>
            <Button
              title='Clear'
              variant='outline-primary'
              size='xs'
              buttonStyles='!border-gray-200 !p-1'
              textStyles='!font-primary-medium'
              onClick={clearFilters}
            />
            <p className='font-primary-medium text-xl text-dark'>FILTERS</p>
            <div className='ml-6'>
              <Image
                src='/img/close-dark.svg'
                alt='close'
                width={16}
                height={16}
                onClick={closeMenu}
              />
            </div>
          </div>
          <div className='mt-10'>
            <div className='space-y-8'>
              <GroupCheckboxButtons
                label='Property Type'
                options={getPropertyTypeOptions(currentFilter, updateFilter)}
              />
              <RangeSliderInput
                label='Price'
                onChange={(minPrice, maxPrice) => {
                  if (minPrice && minPrice !== currentFilter.price_min) {
                    updateFilter(
                      'price_min',
                      !!minPrice && minPrice !== '0' ? minPrice : undefined
                    );
                  }

                  if (maxPrice && maxPrice !== currentFilter.price_max) {
                    updateFilter(
                      'price_max',
                      !!maxPrice && maxPrice !== '0' ? maxPrice : undefined
                    );
                  }
                }}
                minValue={currentFilter.price_min || '0'}
                maxValue={currentFilter.price_max || '0'}
                minValuePlaceholder='Min Price'
                maxValuePlaceholder='Max Price'
                min={DEFAULT_MIN_PRICE_RANGE}
                max={DEFAULT_MAX_PRICE_RANGE}
                step={DEFAULT_RANGE_STEPS}
              />
              <GroupedRadioButtons
                label='Beds'
                value={currentFilter.beds_min}
                onChange={(value) => {
                  if (filter.beds_min !== value) {
                    updateFilter('beds_min', value);
                  } else {
                    updateFilter('beds_min', undefined);
                  }
                }}
                options={BEDS_SELECT_OPTION}
              />
              <GroupedRadioButtons
                label='Baths'
                value={currentFilter.baths_min}
                onChange={(value) => {
                  if (filter.baths_min !== value) {
                    updateFilter('baths_min', value);
                  } else {
                    updateFilter('baths_min', undefined);
                  }
                }}
                options={BATHS_SELECT_OPTION}
              />
              <GroupedCheckBoxInputs
                label='Amenities'
                options={getAmenitiesOptions(currentFilter, updateFilter)}
              />
            </div>
          </div>
        </Container>
      </div>
      <div className='absolute bottom-0 inset-x-0'>
        <div className='bg-white py-3'>
          <Container>
            <Button
              title='Save'
              variant='black'
              buttonStyles='w-full py-3'
              textStyles='w-full text-center !font-primary-medium text-lg'
              onClick={handleSubmit}
            />
          </Container>
        </div>
      </div>
    </div>
  );
};

export default ListingFiltersSm;
